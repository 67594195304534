<template>
  <header class="flex justify-between items-center h-[40px] relative gap-2">
    <div class="userList-notifications flex">
      <div
        id="append_header_icon"
        class="flex justify-center items-center z-[10]"
      ></div>

      <div
        class="dropdown-holder bg-sime-transparent flex relative z-[20] items-center rounded-[30px]"
      >
        <ag-dropdown position="start-0 !top-10">
          <template v-slot:button>
            <div class="flex items-center gap-1 p-1">
              <ag-avatar
                :src="user.user_image"
                imgClasses="w-8 h-8 rounded-[32px] border border-white/opacity-25"
                containerClasses="border border-white/opacity-25 rounded-[50%]"
              />
              <span class="text-[14px] hidden lg:inline ms-3">
                {{ user.full_name }}</span
              >
              <ag-base-icon
                transparent
                name="ph-CaretDown"
                weight="bold"
                containerClasses="!p-0 !w-7 !h-7 cursor-pointer"
                customClasses="text-[#313F52] text-[16px]"
              />
            </div>
          </template>
          <template v-slot:content="{ close }">
            <div class="">
              <div
                @click="close"
                class="h-[100dvh] w-[100dvw] bg-[#FFF] fixed top-0 right-0 md:w-[374px] md:h-[auto] md:relative text-[#313F52] pt-6 pb-3 px-3 rounded-lg"
              >
                <div class="flex gap-1 items-center">
                  <ag-base-icon
                    class="cursor-pointer"
                    transparent
                    name="ph-arrow-right"
                    size="24"
                    color="#000"
                  />
                  <div class="text-[1rem]">
                    {{ $t("back") }}
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center mb-3">
                  <ag-avatar
                    :src="user.user_image"
                    imgClasses="w-[52px] h-[52px] relative rounded-[32px] border border-white/opacity-25"
                    containerClasses="border w-[52px] h-[52px]  border-white/opacity-25 rounded-[50%] mb-3"
                  />
                  <div class="mb-2">{{ user.full_name }}</div>
                  <!-- <div class="mb-3">khaled@gmail.com</div> -->
                  <ag-refactored-btn
                    type="secondary"
                    to="/user/settings/basicData"
                  >
                    <template #leftIcon>
                      <ag-icon name="ph-gear-six" weight="bold" size="sm" />
                    </template>
                    {{ $t("accountSettings") }}
                  </ag-refactored-btn>
                </div>
                <div class="border-t border-[#EAECF0]">
                  <p class="pt-3 pb-3 text-[#313F52] font-medium">
                    مزيد من الحسابات
                  </p>
                </div>

                <!-- accounts -->
                <div class="border p-3 border-[#EAECF0] rounded-[16px]">
                  <button class="main-list-row flex items-center gap-3 w-full">
                    <ag-avatar
                      src="https://www.w3schools.com/howto/img_avatar.png"
                      imgClasses="w-11 h-11 relative rounded-[32px] border border-white/opacity-25"
                    />

                    <div class="name_email flex flex-col items-satrt">
                      <div class="text-start">khaled ayman</div>
                      <div
                        class="text-start text-[12px]text-[#313F52] font-medium"
                      >
                        khaled.gmail.com
                      </div>
                    </div>
                    <div class="account-status ms-auto">
                      <div
                        class="px-3 py-0.5 bg-amber-100 rounded-xl justify-end items-center gap-[5px] flex min-w-fit bg-[#FFF0C8B2] ms-auto"
                      >
                        <div class="pt-1 pb-0.5 justify-start items-start flex">
                          <div
                            class="text-right text-slate-600 text-[10px] font-normal"
                          >
                            الافتراضي
                          </div>
                        </div>
                        <div
                          class="w-[5px] h-[5px] bg-emerald-500 rounded-full border border-emerald-500"
                        ></div>
                      </div>
                    </div>
                  </button>
                  <button class="main-list-row flex items-center gap-3 w-full">
                    <ag-avatar
                      src="https://www.w3schools.com/howto/img_avatar.png"
                      imgClasses="w-11 h-11 relative rounded-[32px] border border-white/opacity-25"
                    />

                    <div class="name_email flex flex-col items-satrt">
                      <div class="text-start">khaled ayman</div>
                      <div class="text-start text-[12px] text-[#313F52]">
                        khaled.gmail.com
                      </div>
                    </div>
                    <div class="account-status ms-auto">
                      <div
                        class="px-3 py-0.5 bg-amber-100 rounded-xl justify-end items-center gap-[5px] flex min-w-fit bg-[#FFF0C8B2] ms-auto"
                      >
                        <div class="pt-1 pb-0.5 justify-start items-start flex">
                          <div
                            class="text-right text-slate-600 text-[10px] font-normal"
                          >
                            الافتراضي
                          </div>
                        </div>
                        <div
                          class="w-[5px] h-[5px] bg-emerald-500 rounded-full border border-emerald-500"
                        ></div>
                      </div>
                    </div>
                  </button>
                </div>
                <!-- actions -->
                <div
                  class="flex flex-col items-start mt-3 text-[#1F242E] font-medium"
                >
                  <div class="flex gap-2.5 px-3 pb-3 pt-2 items-center">
                    <ag-base-icon
                      name="ph-plus"
                      size="24"
                      weight="bold"
                      class="!p-0 !w-10 !h-10 cursor-pointer"
                      style="
                        box-shadow: 0px 1.75px 8px -1px rgba(15, 17, 20, 0.08);
                      "
                      customClasses="w-4 h-4"
                      transparent
                    />
                    <span>{{ $t("addNewAccount") }}</span>
                  </div>
                  <div
                    @click="logOutEvent()"
                    class="flex gap-2.5 px-3 pb-3 pt-2 items-center cursor-pointer"
                  >
                    <ag-base-icon
                      name="ph-SignOut"
                      size="24"
                      weight="bold"
                      class="!p-0 !w-10 !h-10"
                      customClasses="w-4 h-4"
                    />
                    <span>{{ $t("logout") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ag-dropdown>
      </div>
      <!-- <div
        class="h-10 w-10 rounded-full bg-sime-transparent flex items-center justify-center relative ms-2.5 z-[10]"
      >
        <div
          class="notification-num absolute top-[2px] start-[2px] bg-[#F24B4B] text-[#FFF] text-[8px] border border-white/opacity-25 rounded-[10px] px-1 py-0.5 flex items-center justify-center h-[12px] w-[18px]"
        >
          <span> + </span>
          <span class="inline-block mt-0.5"> 9 </span>
        </div>
        <ag-base-icon
          transparent
          name="ph-BellSimple"
          size="16"
          weight="fill"
          class="cursor-pointer text-[#313F52] !p-0 !w-7 !h-7"
        />
      </div> -->
    </div>
    <div
      class="search grow max-w-[450px] md:w-[50%] md:max-w-[50%] grow md:block items-center z-[10]"
    >
      <div class="w-full flex items-center justify-center">
        <ag-autocomplate
          :place-holder="$t('searchInListsAndAppsAndEventsAndMembers')"
          server-filter
          holderClass="!h-[38px]  bg-white rounded-[60px] w-full ps-3   overflow-x-hidden max-w-[592px]"
          customClasses="!text-sm"
        >
          <template #option="{ option }">
            <div class="flex gap-3" v-if="option.value">
              <ag-avatar
                :src="option?.user_image"
                imgClasses="w-11 h-11 relative rounded-[32px] border border-white/opacity-25"
              />
            </div>
          </template>
          <template #prepend-inner>
            <ag-base-icon
              name="ph-MagnifyingGlass"
              size="20"
              weight="bold"
              class="cursor-pointer !p-0 !w-5 !h-5 me-2 text-[#313F52]"
              transparent
            />
          </template>
        </ag-autocomplate>
      </div>
    </div>
    <div class="flex gap-2.5 items-center z-[10]">
      <button
        class="h-10 w-10 rounded-full bg-sime-transparent items-center justify-center flex hidden"
      >
        <ag-base-icon
          transparent
          name="ph-MagnifyingGlass"
          size="16"
          weight="bold"
          class="cursor-pointer text-[#313F52] !p-0 !w-7 !h-7"
        />
      </button>
      <!-- <button
        class="h-10 w-10 rounded-full bg-sime-transparent flex items-center justify-center"
      >
        <ag-base-icon
          transparent
          name="ph-Moon"
          size="16"
          weight="fill"
          class="cursor-pointer text-[#313F52] !p-0 !w-7 !h-7"
        />
      </button> -->
      <router-link class="d-block" to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M23.9699 4.72435L20.974 2.39209C20.8817 2.40403 20.8298 2.4102 20.7371 2.42173L18.4062 5.41917C18.4178 5.51157 18.4241 5.56344 18.4357 5.65585L21.4315 7.98811C21.5239 7.97658 21.5757 7.96999 21.6685 7.95867L23.9993 4.96124C23.9878 4.86883 23.9814 4.81696 23.9699 4.72414V4.72435Z"
            fill="#313F52"
          />
          <path
            d="M13.1192 0.622192L6.3138 0.00146484L4.67086 1.69756C5.20856 3.20327 5.33774 5.3254 4.64247 6.94575C2.70332 11.466 -1.28503 17.2843 0.409544 22.7881H0.40399L0.841929 23.9985H7.44187V23.4304L7.19441 22.7463C4.12986 14.349 4.45714 12.5455 6.30989 7.41376H6.7394L9.97303 16.3519L12.7393 23.9985H20.3955V20.7351L13.1192 0.622192Z"
            fill="#313F52"
          />
        </svg>
      </router-link>
    </div>
  </header>

  <!-- search as model -->
  <ag-dialog
    class="rtl"
    v-model:isOpen="searchModel"
    planeClasses="w-[100%] relative"
  >
    <template v-slot:title>
      <div
        class="h-[72px] sticky w-full top-0 start-0 bg-white text-[#000] text-[20px] px-3 flex items-center justify-between"
      >
        <div class="flex justify-between items-center">
          {{ $t("search") }}
        </div>
        <ag-base-icon
          name="Ph-x"
          class="bg-[#f2f4f7]"
          :transparent="true"
          customClasses="w-5 h-5 "
          containerClasses="w-12 h-12 cursor-pointer"
          weight="bold"
          @click="toggleModal"
        />
      </div>
    </template>
    <template v-slot:content>
      <div class="bg-[#FBFBFB] p-6">
        <div
          class="search grow w-[100%] bg-white rounded-[60px] overflow-x-hidden ps-3"
        >
          <ag-autocomplate
            :place-holder="$t('enterMemberNameOrEmail')"
            server-filter
            holderClass="!h-[40px]"
          >
            <template #option="{ option }">
              <div class="flex gap-3" v-if="option.value">
                <ag-avatar
                  :src="option?.user_image"
                  imgClasses="w-11 h-11 relative rounded-[32px] border border-white/opacity-25"
                />
                <div class="justify-end items-center gap-3 inline-flex">
                  <div
                    class="text-[#000] text-lg font-normal text-start leading-7"
                  >
                    sasas
                  </div>
                </div>
              </div>
            </template>
            <template #prepend-inner>
              <ag-base-icon
                name="ph-MagnifyingGlass"
                size="20"
                class="cursor-pointer !p-0 !w-7 !h-7 me-1"
                transparent
              />
            </template>
          </ag-autocomplate>
        </div>
      </div>
    </template>
  </ag-dialog>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import { ai } from "@/plugins/axios"

import { storeToRefs } from "pinia"

const authStore = useAuthStore()
const { logout } = useAuthStore()

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const { entity_id, space_id, election_id } = toRefs(route.params)

const show = ref(false)
const rendered = ref(false)
function logOutEvent() {
  const { saveUser } = useAuthStore()
  saveUser(null)
  localStorage.removeItem("user")
  localStorage.removeItem("jwt")
  localStorage.removeItem("user_id")
  logout()

  router.push({ name: "login" })
}
const searchModel = ref(false)
const toggleModal = () => {
  searchModel.value = !searchModel.value
}
const user = computed(() => JSON.parse(localStorage.getItem("user") || "{}"))
const getUserInfo = async () => {
  const res = await ai.get(
    "method/agenda.api.user.user.get_member_information",
    {
      params: {
        member_id: JSON.parse(localStorage.getItem("user")).member_id
      }
    }
  )
  userData.value = res.data
  setColor(res.data.color)
  resetForm({
    values: {
      first_name: userData.value.full_name,
      username: userData.value.username
    }
  })
}
console.log(user.value)
</script>

<style lang="scss" scoped>
.bg-sime-transparent {
  background: rgba(255, 255, 255, 0.3);
}

.headlessui-combobox-input-3 {
}

/* Extra Small Devices (Phones) */
@media (max-width: 600px) {
  /* Styles for phones */
}

/* Small Devices (Tablets) */
@media (min-width: 601px) and (max-width: 768px) {
  /* Styles for small tablets */
}

/* Medium Devices (Landscape Tablets and Small Laptops) */
@media (min-width: 769px) and (max-width: 992px) {
  /* Styles for landscape tablets and small laptops */
}

/* Large Devices (Desktops) */
@media (min-width: 993px) and (max-width: 1200px) {
  /* Styles for desktops and laptops */
}

/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) {
  /* Styles for large desktops */
}

/* Ultra-Wide Screens */
@media (min-width: 1921px) {
  /* Styles for ultra-wide screens */
}
</style>
