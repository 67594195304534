<template>
  <component
    :is="to ? 'router-link' : 'button'"
    class="ag-btn-refactored"
    :class="[
      `ag-btn-refactored--${type}`,
      { 'ag-btn-refactored--disabled': disabled },
      { 'ag-btn-refactored--loading': loading }
    ]"
    v-bind="$attrs"
    :disabled="disabled || loading"
    :to="to"
    type="button"
  >
    <svg
      v-if="loading"
      class="animate-spin -ml-1 mr-3 h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <slot
      v-if="$slots.leftIcon && !loading"
      name="leftIcon"
      class="ag-btn-refactored__icon"
    />
    <slot />
    <slot
      v-if="$slots.rightIcon && !loading"
      name="rightIcon"
      class="ag-btn-refactored__icon"
    />
  </component>
</template>

<script>
export default {
  name: "AgRefactoredBtn",
  props: {
    type: {
      type: String,
      default: "primary",
      validator: (value) =>
        ["primary", "secondary", "tertiary", "danger"].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.ag-btn-refactored {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  min-width: 64px;
  padding: 10px 24px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  // to make content take atleast 64px and the padding adding to total width
  box-sizing: content-box !important;
  transition: all 0.2s ease;
  text-decoration: none;
  outline: 3px solid transparent;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Types
  &--primary {
    background: black;
    color: white;

    &:hover:not(:disabled) {
      background: #3a424d;
    }

    &:focus:not(:disabled) {
      background: black;
      outline: 3px solid #1f242e26;
    }

    &.ag-btn-refactored--disabled {
      background: #f5f5f4;
      color: white;
      cursor: not-allowed;
    }
  }

  &--secondary {
    background: #f2f4f7;
    color: #1f242e;

    &:hover:not(:disabled) {
      background: #d0d5dd;
    }

    &:focus:not(:disabled) {
      background: #f2f4f7;
      outline: 3px solid #1f242e26;
      color: #1f242e;
    }

    &.ag-btn-refactored--disabled {
      border-color: #0c1b231f;
      color: #0c1b2333;
      cursor: not-allowed;
    }
  }

  &--tertiary {
    background: transparent;
    color: #1f242e;
    outline: 1px solid #1f242e;

    &:hover:not(:disabled) {
      background: #3a424d0f;
    }

    &:focus:not(:disabled) {
      outline: 3px solid #1f242e26;
      color: #1f242e;
    }

    &.ag-btn-refactored--disabled {
      color: #0c1b2333;
      cursor: not-allowed;
      outline: 3px solid #0c1b231f;
    }
  }

  &--danger {
    background: #f24b4b;
    color: white;

    &:hover:not(:disabled) {
      background: #d93636;
    }

    &:focus:not(:disabled) {
      background: #f24b4b;
      outline: 3px solid #f24b4b26;
    }

    &.ag-btn-refactored--disabled {
      background: #feeded;
      color: #f24b4b;
      cursor: not-allowed;
    }
  }
}
</style>
